import { Routes } from '@angular/router';

import { ApiPage, GotoPage, ConfirmPage } from '@btlib-core';

const routesCompany: Routes = [

  { 
    path: 'sitemap', 
    loadComponent: () => import('./pages/sitemap/sitemap.page').then(m => m.SitemapPage) 
  },
  {
    path: ':lang',
    children: [
      { path: '', loadComponent: () => import('./pages/company/index/index.page').then(m => m.IndexPage)  },
      { path: 'search', loadComponent: () => import('./pages/company/search/search.page').then(m => m.SearchPage)  },
      { path: 'p/:pType/:pInfo/:pName', loadComponent: () => import('./pages/company/product/product.page').then(m => m.ProductPage)  },

      { path: 'checkout', loadComponent: () => import('./pages/company/checkout/checkout.page').then(m => m.CheckoutPage)  },
      { path: 'payment', loadComponent: () => import('./pages/company/payment/payment.page').then(m => m.PaymentPage)  },
      { path: 'payment-complete', loadComponent: () => import('./pages/company/payment-complete/payment-complete.page').then(m => m.PaymentCompletePage)  },
      
      { path: 'eula', loadComponent: () => import('./pages/company/eula/eula.page').then(m => m.EulaPage)  },
      { path: 'privacy', loadComponent: () => import('./pages/company/privacy/privacy.page').then(m => m.PrivacyPage)  },
     

      
      { 
        path: 'sitemap', 
        loadComponent: () => import('./pages/sitemap/sitemap.page').then(m => m.SitemapPage) 
      },

      // https://192.168.1.60:8204/booking/nesbyen/no/p/Overnatting/Hytter%20og%20leiligheter/Askeladden%20-%20Svært%20komfortabel%20og%20solrik%20familiehytte-807?p=807

      {
        path: 'user',
        children: [
          { path: '', loadComponent: () => import('./pages/company/user/index/index.page').then(m => m.IndexPage)  },
        ]
      },


      {
        path: 'misc',
        children: [
          { path: 'offer', loadComponent: () => import('./pages/company/misc/offer/offer.page').then(m => m.OfferPage)  },
        ]
      },
      
      {
        path: "template-page",
        loadComponent: () => import("./pages/template-page/template-page.page").then((m) => m.TemplatePagePage),
        
      },
      {
        path: "lib",
        children: [
          { path: "api", component: ApiPage },
          { path: "goto", component: GotoPage },
          { path: "user", children: [
            { path: "confirm", component: ConfirmPage },
          ]},
        ]
      }

    ]
  },
  

];

const routesCommon: Routes = [
  { 
    path: '', 
    loadComponent: () => import('./pages/index/index.page').then(m => m.IndexPage) 
  },
 
  ...routesCompany,
  {
    path: "booking",
    children: [
      {
        path: ':cid',
        children: routesCompany,
    
      },
      {  path: '', redirectTo: '/', pathMatch: 'full' },
    ]
  },


  { 
    path: 'en',  pathMatch: 'full' ,
    loadComponent: () => import('./pages/index/index.page').then(m => m.IndexPage) 
  },  
  { 
    path: 'no',  pathMatch: 'full',
    loadComponent: () => import('./pages/index/index.page').then(m => m.IndexPage) 
  }
  

];

export const routes: Routes = [

  ...routesCommon,
    
  // { // Brukes på test
  //   path: 'api/:cid',
  //   children: routesCommon
  // },

  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

