export const environment = {
  
  production: false,
  settings: {
    env: false,
    appType: "btng",
    appId: "cbsite",
    cid: "_",
    name: "cbsite",
    langs: [ "no", "en" ], // 
    pushTopics: [ ],
    standalone: true,

    plugins: {
      klaro: {
        enabled: true
      }
    },
    
    
  }
};
